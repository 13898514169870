.notification-container {
  position: fixed;
  z-index: 999999;
  background-color: #ffffffeb;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-container .content {
  height: auto;
}

.notification {
  background-color: #bf0426;
  border: 5px solid #fff;
  color: #fff;
  position: relative;
  padding: 15px 30px;
  margin-bottom: 15px;
  width: 500px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-bottom 0.7s;
  border-radius: 25px;
  box-shadow: 5px 7px 7px #ccc;
}

.notification > div {
  padding: 25px 15px;
  text-align: center;
}

.notification .notification-production {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid #fff;
}

.notification > div:nth-child(2) {
  text-align: center;
}

.notification-number {
  font-size: 60px;
  font-weight: bold;
}

.notification-name {
  font-size: 20px;
}
.notification-icon {
  display: flex;
  justify-content: center;
  padding: 0 0 40px 0 !important;
}
.notification svg {
  font-size: 50px;
  text-align: center;
  stroke: #bf0426;
  background-color: #fff;
  border-radius: 200px;
  padding: 25px;
  position: absolute;
  top: -50px;
}

@keyframes toast-in-bottom {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}
