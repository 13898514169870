@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
	--c1: #494373;
	--c1c: #fff;
	--c2: #d50000;
	--c2c: #fff;
	--p0: red;
	--p1: green;
	--p2: orange;
	--p3: pink;
}

html {
	height: 100vh;
	height: -webkit-fill-available;
}

body {
	height: 100%;
	font-family: "Poppins", sans-serif;
	margin: 0;
	overflow: hidden;
}

div {
	box-sizing: border-box;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	background-color: #213740;
	color: #fff;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header h2 {
	margin: 0;
}

.header .header-left {
	display: flex;
	gap: 10px;
	align-items: center;
}

.header .logo {
	height: 25px;
}

.header .logo img {
	max-width: 100%;
	height: 100%;
}

.content {
	height: 100%;
}

.row {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	background-color: #d9d9d9;
}

.col {
	display: flex;
	flex-direction: column;
	padding: 15px;
}

.col .title {
	font-weight: bold;
	font-size: 40px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #213740;
	color: #fff;
	border-radius: 5px;
	text-align: center;
	padding: 5px;
}

.content.v2 {
	display: grid;
	grid-template-columns: auto auto;
}

.content.v2 .title {
	padding: 15px;
	text-transform: uppercase;
	font-weight: bold;
}

.content.v2 .title > div {
	padding: 5px;
	background-color: #213740;
	color: #fff;
	border-radius: 5px;
	height: 100%;
	display: flex;
	align-items: center;
}

.content.v2 .list {
	padding: 15px;
}

.content.v2 .list .order {
	height: 100%;
}

.list {
	display: grid;
	gap: 15px;
	padding-top: 15px;
	align-items: flex-start;
}

.order {
	background-color: #ffffff;
	border-radius: 5px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.order .name {
	font-size: 22px;
	margin-bottom: 10px;
	color: #999;
}

.order .number {
	font-size: 50px;
	font-weight: bold;
	line-height: calc(var(--order_size) - 20px);
}

.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #00000095;
	display: flex;
	align-items: center;
	justify-content: center;
}

.popup .content {
	background-color: #fff;
	width: 300px;
	height: auto;
}

.error {
	position: fixed;
	bottom: 30px;
	right: 30px;
	background-color: #d50000;
	color: #fff;
	padding: 15px;
	border-radius: 10px;
	box-shadow: 0px 0px 10px #00000050;
}
